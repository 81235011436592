<template>
  <section>
    <form v-on:submit.prevent class="form" role="form" ref="form">
      <FormParentsSelection
        v-if="initialized && showParentSelection"
        v-model="parent"
        v-bind:filters="filters"
        v-bind:locker="device_id != 0"
      />

      <div v-if="ready">
        <FormCommonFieldsInline
          v-model="data"
          :errors="errors"
          :disabled="!canNameIt"
          :isModelBased="isModelBased"
        />

        <div class="box box-solid">
        <div class="box-body">
        <!-- begin ProcessArea and saving mode and history-->
        <div class="row">
          <div class="col-lg-5">
            <ProcessAreaSelection
              label="device"
              v-bind:parentProcessAreaId="parentProcessAreaId"
              v-bind:value="process_area"
              v-model="process_area"
              v-bind:disabled="busy"
            />
          </div>
        </div>
        <!-- end ProcessArea -->

        <!-- begin data type and mqtt topic -->

        <div class="data-configuration">
          <div class="row" v-if="isMQTT">
            <div class="col-md-6 x">
              <MemoryTypeSelector
                v-model="data.memory_type.id"
                :memoryTypeList="memoryTypeList"
                :disabled="areMemoryFieldsDisabled"
                :local.sync="isLocal"
                :localDisabled="Boolean(data.id)"
                :parser="memoryTypeListParser"
                :previous="(source && source.id && source.memory_type) || null"
              />
            </div>
            <div class="col-md-6" v-if="!isLocal">
              <div class="form-group">
                <label for=""
                  >{{ $t("topic") }} MQTT
                  <ToolTip :title="$t('hints.mqtt_topic')" />
                </label>
                <div class="input-group">
                  <div
                    class="input-group-addon input-prefix-label"
                    :style="styleInputAddon"
                  >
                    {{ topicPrefix }}/
                  </div>
                  <input
                    class="form-control no-focus-border"
                    :value="label"
                    :disabled="areMemoryFieldsDisabled"
                    @input="setLabel($event)"
                    style="padding-left: 0; border-left: 0"
                  />
                  <MQTTTopicSpan class="input-group-addon btn" :entry="data" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div :class="genericMemoryTypeSelectorColClass">
              <MemoryTypeSelector
                v-model="data.memory_type.id"
                :memoryTypeList="memoryTypeList"
                :disabled="areMemoryFieldsDisabled"
                :local.sync="isLocal"
                :localDisabled="Boolean(data.id)"
                :parser="memoryTypeListParser"
                :previous="(source && source.id && source.memory_type) || null"
              />
            </div>
            <div
              class="col-md-2"
              v-if="
                (memoryVectorSizeConstraints || stringLengthConstraints) &&
                  !isLocal
              "
            >
              <!-- BEGIN vector size -->
              <!-- :style="{ width: stringLengthConstraints ? '50%' : '100%' }" -->
              <div
                :class="
                  stringLengthConstraints
                    ? 'form-group  form-group-inline'
                    : 'form-group'
                "
                v-if="memoryVectorSizeConstraints"
              >
                <label
                  for="vector_size"
                  class="clicable no-select"
                  @click.stop.prevent="vectorEnabled = !vectorEnabled"
                >
                  <i
                    :class="
                      vectorEnabled ? 'fa fa-check-square-o' : 'fa fa-square-o'
                    "
                  />
                  {{
                    stringLengthConstraints
                      ? $tc("titles.element", 2)
                      : $t("titles.vector_size")
                  }}
                  <ToolTip
                    :title="
                      $t('hints.vector_size', {
                        min: memoryVectorSizeConstraints.min,
                        max: memoryVectorSizeConstraints.max
                      })
                    "
                  />
                </label>
                <input
                  id="vector_size"
                  class="text-center form-control no-padding"
                  type="number"
                  ref="memorySize"
                  placeholder="1"
                  v-model="memorySize"
                  :class="{invalid: !isMemoryVectorSizeValid}"
                  :step="memoryVectorSizeConstraints.inc"
                  :max="memoryVectorSizeConstraints.max"
                  :min="memoryVectorSizeConstraints.min"
                  :disabled="busy || isModelBased"
                />
              </div>
              <!-- END memory length -->

              <!-- BEGIN single element length -->
              <div
                :class="
                  memoryVectorSizeConstraints
                    ? 'form-group form-group-inline'
                    : 'form-group'
                "
                v-if="stringLengthConstraints"
              >
                <label for="memory_size">
                  {{ $t("size") }}
                  <ToolTip
                    :title="
                      $t('hints.memory_size', {
                        min: stringLengthConstraints.min,
                        max: stringLengthConstraints.max
                      })
                    "
                  />
                </label>
                <input
                  id="string_length"
                  class="text-center form-control no-padding"
                  type="number"
                  ref="stringLength"
                  v-model="stringLength"
                  placeholder="1"
                  :class="{invalid: !isStringLengthValid}"
                  :step="stringLengthConstraints.inc"
                  :max="stringLengthConstraints.max"
                  :min="stringLengthConstraints.min"
                  :disabled="busy || isModelBased"
                />
              </div>
              <!-- END  single element length -->
            </div>
            <div
              :class="
                `col-md-${
                  data.source_value.id == getSourceValueConst.ADDRESS ||
                  isReadOnly
                    ? 4
                    : 2
                }`
              "
              v-if="!isLocal"
            >
              <div class="form-group">
                <label for="value_source"
                  >{{ $t("value_source")
                  }}<ToolTip :title="$t('hints.value_source')"
                /></label>
                <select
                  class="form-control"
                  data-testid="value-source"
                  v-model="data.source_value.id"
                  id="value_source"
                  :disabled="areMemoryFieldsDisabled"
                >
                  <option
                    v-for="(item, ix) in sourceValueList"
                    v-bind:key="ix"
                    v-bind:value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="col-md-2"
              v-if="
                (data.source_value.id == getSourceValueConst.ADDRESS ||
                  !isReadOnly) &&
                  !isLocal
              "
            >
              <div
                class="form-group"
                :class="{
                  'has-error':
                    data.memory_address === '' ||
                    (errors && errors.memory_address)
                }"
              >
                <label for="memory_address" style="white-space: nowrap"
                  >{{ $t("memory_address") }}
                  <ToolTip :title="$t('hints.memory_address')" />
                </label>
                <input
                  type="number"
                  class="form-control text-center"
                  data-testid="memory-address"
                  v-model="data.memory_address"
                  id="memory_address"
                  v-bind:disabled="busy || isModelBased"
                  :title="errors && $t(errors.memory_address)"
                />
              </div>
            </div>
            <div
              class="col-md-2"
              v-if="
                data.source_value.id == getSourceValueConst.LOCAL && !isLocal
              "
            >
              <div
                class="form-group"
                :class="{
                  'has-error':
                    data.local_storage_identity_number === '' ||
                    (errors && errors.local_storage_identity_number)
                }"
              >
                <label for="identity_number">{{
                  $t("data_identification")
                }}</label>
                <input
                  type="number"
                  class="form-control text-center"
                  data-testid="storage-identity-number"
                  v-model="data.local_storage_identity_number"
                  id="identity_number"
                  v-bind:disabled="busy || isModelBased || isFreePlan"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- end data type and mqtt topic -->

        <div class="row">
          <div class="col-lg-3 col-sm-6" v-if="accessModeBlockVisible">
            <div class="form-group">
              <label
                >{{ $t("titles.access_mode") }}
                <ToolTip :title="$t('hints.access_mode')"
              /></label>
              <select
                class="form-control"
                style="padding: 0 5px"
                v-model="data.read_write_mode"
                data-testid="read-write-mode"
                :disabled="!isWritingAllowed || areMemoryFieldsDisabled"
              >
                <option
                  :value="item.id"
                  v-for="item in accessModeList"
                  :key="item.id"
                >
                  {{ $t(item.title) }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="col-lg-2 col-sm-3"
            v-if="configurationsBlockVisible"
            style="margin-bottom: 30px"
          >
            <div class="form-group">
              <label>{{ $tc("configuration", 2) }}</label>
              <div class="checkbox-group">
                <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    data-testid="allowed_mapping_value"
                    v-model="data.allowed_mapping_value"
                    v-bind:disabled="busy || isModelBased || isLocal"
                  />
                  {{ $t("mapping") }}
                  <ToolTip :title="$t('hints.data_mapping')" />
                </label>
              </div>
            </div>
          </div>

          <!-- begin history type selection -->
          <div class="col-lg-2 col-sm-6" v-if="historyTypeBlockVisible">
            <div class="form-group">
              <label for="history_type">
                <span
                  class="clicable"
                  @click.stop.prevent="
                    data.history_enabled = !data.history_enabled
                  "
                >
                  <i
                    :class="
                      data.history_enabled
                        ? 'fa fa-check-square-o'
                        : 'fa fa-square-o'
                    "
                  ></i>
                  {{ $t("history_type") }}
                </span>
                <ToolTip :title="hintsHistoryType" />
                <ToolTip
                  style="margin-left: 10px"
                  icon="fa fa-exclamation-triangle text-danger"
                  :title="
                    $t('hints.option_quota_warning', {
                      item: historyTypeName
                    })
                  "
                  v-if="quota_warning"
                />
              </label>
              <select
                class="form-control"
                :class="{hideIt: !data.history_enabled}"
                v-model="data.data_history_type_id"
                data-testid="data-history-type"
                :disabled="busy || isModelBased"
              >
                <option
                  v-for="(item, ix) in historyTypeList"
                  :key="ix"
                  :value="item.number"
                  :title="item.description"
                >
                  {{ item.name }}
                </option>
              </select>
              <select
                class="form-control"
                :class="{hideIt: data.history_enabled}"
                disabled
              >
                <option value="">
                  {{ $tc("disabled", 1) }}
                </option>
              </select>
            </div>
          </div>
          <!-- end history type selection -->

          <!-- begin data history rate -->
          <div
            class="col-sm-6"
            :class="{
              'col-lg-5': !historyToleranceValueVisible,
              'col-lg-3': historyToleranceValueVisible
            }"
            v-if="!isFreePlan && dataHistoryRateVisible"
          >
            <label>
              {{ this.$t("titles.history_rate") }}
              <ToolTip :title="$t('hints.data_history_rate')" />
            </label>
            <DataHistoryRateSelector
              v-model="data.history_rate"
              :allowNone="true"
              :contract="contract"
              :connector="connector"
            />
          </div>
          <!-- end data history rate  -->

          <!-- begin history_tolerance_value -->
          <div
            class="col-lg-2 col-sm-6 form-group"
            :class="{'has-error': !historyToleranceValueIsValid}"
            v-if="!isFreePlan && historyToleranceValueVisible"
          >
            <label>
              {{ this.$t("titles.history_tolerance_value") }}
              <ToolTip :title="$t('hints.history_tolerance_value')" />
            </label>
            <input
              v-if="isString || isFloat"
              type="text"
              class="form-control"
              :placeholder="$t('no_history_tolerance_value')"
              data-testid="history_tolerance_value"
              v-model="computedHistoryToleranceValue"
              v-bind:disabled="busy"
              id="history_tolerance_value"
            />
            <input
              v-else
              type="number"
              class="form-control"
              :placeholder="$t('no_history_tolerance_value')"
              data-testid="history_tolerance_value"
              v-model="computedHistoryToleranceValue"
              v-bind:disabled="busy"
              step="any"
              min="0"
              :max="historyToleranceValueMax"
              id="history_tolerance_value"
            />
          </div>
          <!-- end history_tolerance_value  -->
        </div>

        <div class="row" v-if="mqttQosBlockVisible || mqttRetainBlockVisible">
          <div class="col-lg-3 col-sm-6 sm-new-line" v-if="mqttQosBlockVisible">
            <div class="form-group">
              <label>
                {{ $t("mqtt_qos") }}
                <ToolTip :title="$t('hints.mqtt_qos')" />
              </label>
              <select name="" id="" class="form-control" v-model="mqtt_qos">
                <option value="0">{{ $t("at_most_once") }} (QoS 0)</option>
                <option value="1">{{ $t("at_least_once") }} (QoS 1)</option>
                <!-- <option value="2">{{ $t("exactly_once") }} (QoS 2)</option> -->
              </select>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6" v-if="mqttRetainBlockVisible">
            <div class="form-group">
              <label>
                {{ $t("mqtt_retain") }}
                <ToolTip :title="$t('hints.mqtt_retain')" />
              </label>
              <div>
                <label class="checkbox-inline">
                  <input type="radio" v-model="mqtt_retain" :value="false" />
                  {{ $t("no") }}
                </label>
                <label class="checkbox-inline">
                  <input type="radio" v-model="mqtt_retain" :value="true" />
                  {{ $t("yes") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- begin disable history warning -->
        <div
          class="row"
          v-if="
            history_enabled &&
              !data.history_enabled &&
              !memoryVectorSizeConstraints
          "
        >
          <div class="col-xs-12">
            <div
              style="margin: 0"
              class="alert alert-default text-center text-danger"
              v-html="$t('hints.history_disabled')"
            />
          </div>
        </div>
        <!-- end disable history warning -->

        <!-- begin after save options -->
        <div class="row" v-if="!isReadOnly && !isMQTT && !isLocal">
          <div class="col-md-12">
            <div class="form-group">
              <label
                >{{ $t("after_save_action") }}
                <ToolTip
                  :title="
                    $t('hints.select_the_option_based_on_the_item_description')
                  "
                />
              </label>
              <div
                class="radio"
                v-for="it in $root.config.references
                  .data_update_value_modes_after_writing"
                v-bind:key="it.id"
              >
                <label style="margin-bottom: 10px">
                  <input
                    type="radio"
                    :value="it.id"
                    v-model="data.update_value_mode_after_writing.id"
                    :disabled="areMemoryFieldsDisabled"
                  />
                  {{ it.name }}
                  <div class="small text-info">
                    {{ it.description }}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- end after save options -->

        <!-- begin min/max - calculations -->
        <template v-if="!isString && memorySize == 1">
          <!-- begin min/max aquisition interval -->
          <div>
            <label for="minimum_value">
              <template v-if="!isLocal">
                {{ $t("aquisition_interval") }}
                <ToolTip :title="$t('hints.aquisition_interval')" />
              </template>
              <template v-else>
                {{ $t("aquisition_interval_local_data") }}
                <ToolTip :title="$t('hints.aquisition_interval_local_data')" />
              </template>
            </label>
            <div class="row">
              <div
                class="form-group col-md-3 col-xs-6"
                :class="{'has-error': hasIntervalError}"
              >
                <div class="input-group">
                  <div class="input-group-addon">
                    {{ $t("minimum") }}
                  </div>
                  <input
                    type="number"
                    class="form-control text-center"
                    v-model="data.minimum_value"
                    id="minimum_value"
                    v-bind:disabled="busy"
                    :step="isFloat ? '.0001' : '1'"
                  />
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="data.minimum_value = ''"
                  >
                    <i class="fa fa-close" :title="$t('remove')"></i>
                  </div>
                </div>
              </div>
              <div
                class="form-group col-md-3 col-xs-6"
                :class="{'has-error': hasIntervalError}"
              >
                <div class="input-group">
                  <div class="input-group-addon">
                    {{ $t("maximum") }}
                  </div>
                  <input
                    type="number"
                    class="form-control text-center"
                    v-model="data.maximum_value"
                    id="maximum_value"
                    v-bind:disabled="busy"
                    :step="isFloat ? '.0001' : '1'"
                  />
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="data.maximum_value = ''"
                  >
                    <i class="fa fa-close" :title="$t('remove')"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end min/max aquisition interval -->

          <!-- begin calculations -->
          <div class="row" v-if="!isLocal">
            <div class="col-md-3">
              <div class="form-group">
                <label for="calculation"
                  >{{ $t("calculation") }}
                  <ToolTip :title="$t('hints.calculation')" />
                </label>
                <select
                  class="form-control"
                  data-testid="calculation-type"
                  v-model="data.calculation_type.id"
                  id="calculation"
                  v-bind:disabled="busy || isModelBased"
                >
                  <option
                    v-for="(item, ix) in calculationList"
                    v-bind:key="ix"
                    v-bind:value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="col-md-3"
              v-for="(calculationParam, index) in calculationParamsFrom(
                data.calculation_type.id
              )"
              :key="index"
            >
              <div class="form-group">
                <label for="param_a">{{
                  `${$tc("parameter")} ${calculationParam.letter.toUpperCase()}`
                }}</label>
                <input
                  type="text"
                  class="form-control calculation_param"
                  v-model="data[`calculation_param_${calculationParam.letter}`]"
                  :id="'param_' + calculationParam.letter"
                  :pattern="calculationParam.regex"
                  :required="calculationParam.required"
                  :data-content="calculationParam.hint"
                  data-toggle="popover"
                  v-bind:disabled="busy"
                />
              </div>
            </div>
            <div class="col-md-3" v-if="data.calculation_type.id > 0">
              <div class="form-group">
                <label for="param_b">{{ $t("result") }}</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-bind:value="
                    $t('data_calculation_' + data.calculation_type.id)
                  "
                />
              </div>
            </div>
          </div>
          <!-- end calculations -->
        </template>
        <!-- end min/max - calculations -->

        <!-- begin data formating -->
        <div class="data-configuration">
          <div class="row">
            <!-- begin format type -->
            <div class="col-md-3">
              <div class="form-group form-group-sm">
                <label for="value_format_type">
                  {{ $t("format") }}
                  <ToolTip :title="$t('hints.format')" />
                </label>
                <select
                  class="form-control"
                  data-testid="value-format"
                  v-model="valueFormatTypeId"
                  id="value_format_type"
                  v-bind:disabled="busy || isModelBased"
                >
                  <option
                    v-for="(item, ix) in formatList"
                    v-bind:key="ix"
                    v-bind:value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- begin format type -->

            <!-- end text-list format configuration -->
            <div
              class="col-md-9"
              v-if="format && format.format_mask == 'text_list'"
            >
              <div class="form-group form-group-sm">
                <label for="unity_label">
                  {{ $tc("text_list", 1) }}
                  <ToolTip :title="$t('hints.text_list')" />
                </label>
                <FormTextListInline
                  v-model="text_list_id"
                  v-bind:isModelBased="isModelBased"
                />
              </div>
            </div>
            <!-- end text-list format configuration -->

            <!-- begin duration format configuration -->
            <template v-else-if="format && format.format_mask == 'duration'">
              <div class="col-md-3">
                <div class="form-group form-group-sm">
                  <label for="unity_label">{{ $t("duration") }}</label>
                  <select class="form-control" v-model="data.unity_label">
                    <!-- <option value="milliseconds">{{
                      $tc("millisecond", 2)
                    }}</option>
                    <option value="seconds">{{ $tc("second", 2) }}</option>
                    <option value="minutes">{{ $tc("minute", 2) }}</option>
                    <option value="hours">{{ $tc("hour", 2) }}</option> -->
                    <option
                      v-for="k in Object.keys(durations)"
                      :key="k"
                      :value="k"
                    >
                      {{ $tc(durations[k], 2) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group form-group-sm">
                  <label for="unity_label">{{ $t("custom_format") }}</label>
                  <DataFormatInput
                    v-model="customFormat"
                    :showLabel="false"
                    :inputTypeList="['duration_format']"
                    :durationUnit="data.unity_label"
                    style="margin-bottom: 0"
                  />
                </div>
              </div>
            </template>
            <!-- end duration format configuration -->

            <!-- begin custom format configuration -->
            <template v-else-if="format && format.format_mask == 'custom'">
              <div class="col-md-3">
                <div class="form-group form-group-sm">
                  <label for="custom_format">{{ $t("custom_format") }}</label>
                  <input
                    id="custom_format"
                    v-model="customFormat"
                    placeholder="Temp %.2f C°"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
            </template>
            <!-- end custom configuration -->

            <!-- begin string format configuration -->
            <template v-else-if="format && format.format_mask == '%s'">
              <div class="col-md-3 col-sm-6">
                <div class="form-group form-group-sm">
                  <label for="custom_format">{{ $t("custom_format") }}</label>
                  <input
                    id="custom_format"
                    v-model="customFormat"
                    placeholder="%s"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
            </template>
            <!-- begin string format configuration -->

            <!-- begin default numeric format configuration -->
            <div class="col-md-3 col-sm-6" v-else>
              <div class="form-group form-group-sm">
                <label for="unity_label">{{ $t("unit") }}</label>
                <input
                  type="text"
                  class="form-control"
                  data-testid="unit-label"
                  v-model="data.unity_label"
                  id="unity_label"
                  v-bind:disabled="busy || isModelBased"
                />
              </div>
            </div>
            <!-- end default numeric format configuration -->

            <!-- begin default numeric format configuration -->
            <div class="col-md-6 col-sm-6" v-if="currentValueEnabled">
              <InputCurrentValueLocalData
                v-model="data.current_value"
                formGroupClass="form-group-sm"
                :valid="isValidCurrentValue"
                :disabled="busy"
              />
            </div>
            <!-- end default numeric format configuration -->

            <!-- BEGIN chart configutation -->
            <div class="col-md-6 col-sm-6" v-if="data.history_enabled">
              <form class="form-inline custom-data-view" @submit.prevent>
                <div class="form-group form-group-sm" v-if="!isString">
                  <label for="history-wave-form"
                    >{{ $t("wave_form") }}
                    <ToolTip :title="$t('hints.wave_form')"
                  /></label>
                  <select
                    class="form-control"
                    id="history-wave-form"
                    v-model="waveform"
                    :title="$t('hints.enable_history')"
                  >
                    <option value="triangle">
                      {{ $t("triangle") }}
                    </option>
                    <option value="square">
                      {{ $t("square") }}
                    </option>
                    <option value="sin">
                      {{ $t("sin") }}
                    </option>
                  </select>
                </div>
                <div class="form-group form-group-sm">
                  <label>
                    {{ $tc("color") }}
                    <ToolTip :title="$t('hints.color')" />
                  </label>
                  <ColorPicker
                    v-model="color"
                    :pickerStyle="{
                      top: '-269px',
                      right: '-62px'
                    }"
                    :title="$t('hints.enable_history')"
                  />
                </div>
                <div class="form-group form-group-sm">
                  <label>
                    {{ $tc("titles.standard_curve") }}
                    <ToolTip :title="$t('hints.standard_curve')" />
                  </label>
                  <StandardCurveUploadForm
                    style="width: 100%"
                    v-model="standardCurve"
                  >
                  </StandardCurveUploadForm>
                </div>
              </form>
            </div>
            <!-- END chart configutation -->

            <!-- BEGIN of array value data index -->
            <div class="col-md-3 col-sm-6" v-if="dataValueIndex">
              <ValueSourceSelector
                v-model="dataValueIndex"
                :connectorId="connector_id"
                :exclude="data_id ? [data_id] : []"
                :disabled="!dataValueIndex.enabled"
              >
                <template #label>
                  <div>
                    <label
                      class="clicable no-select"
                      for="data_value_index"
                      @click.stop.prevent="toggleDataValueIndex"
                    >
                      <i
                        :class="
                          dataValueIndex.enabled
                            ? 'fa fa-check-square-o'
                            : 'fa fa-square-o'
                        "
                      />
                      {{ $t("titles.data_value_index") }}
                      <ToolTip
                        :title="
                          `${$t('hints.data_value_index')}<br/>${$tc(
                            'interval',
                            1
                          )}: &ge; 0 &amp;&amp; &le; ${memorySize - 1}`
                        "
                      />
                    </label>
                    <div class="pull-right">
                      <span class="small text-primary">
                        &ge; 0 &amp;&amp; &le;
                        {{ memorySize - 1 }}
                        &nbsp;&nbsp;
                      </span>
                    </div>
                  </div>
                </template>
              </ValueSourceSelector>
            </div>
            <!-- END of array value data index -->
          </div>
        </div>
        <!-- end data formating -->

        <FormInlineModel
          v-if="isModel"
          v-model="connectorModel"
          v-bind:isConnector="false"
        />
        <div
          class="row"
          style="margin-top: 3rem"
          v-if="data_id && !isModel && !cloneEnabled"
        >
          <ClearHistory target="data" :targetId="data_id" class="col-md-4" />
        </div>
        </div>
        </div>
      </div>

      <div v-if="no_data">
        <div class="alert alert-info">
          <i class="icon fa fa-warning"></i> {{ $t("no_data") }}
        </div>
      </div>

      <FormFooterToolbar
        v-if="ready"
        v-bind:remove="canRemove"
        v-bind:busy="busy"
        v-bind:valid="isValid"
        v-bind:clone="true"
        v-bind:value="cloneEnabled"
        v-model="cloneEnabled"
        v-on:buttonCancelClick="onButtonCancelClick"
        v-on:buttonSaveClick="onButtonSaveClick"
        v-on:buttonCloneClick="onButtonCloneClick"
        v-on:buttonRemoveClick="onButtonRemoveClick"
        rule="DadoEscrita"
      />
    </form>
  </section>
</template>

<script>
import FormBase from "@/components/registration/form-base.vue";
import FormParentsSelection from "@/components/registration/form-parents-selection.vue";
import FormCommonFieldsInline from "@/components/registration/form-common-fields-inline.vue";
import FormInlineModel from "@/components/registration/form-inline-model.vue";
import FormTextListInline from "@/components/registration/form-text-list-inline.vue";
import DataFormatInput from "@/components/control-sidebar/property-editors/data-format-input.vue";

import ColorPicker from "@/components/editor/color-picker";
import ProcessAreaSelection from "@/components/processarea-selection.vue";
import ToolTip from "@/components/tooltip.vue";
import ClearHistory from "@/components/history/clear-history";
import ConnectorService from "@/services/connector.js";
import DeviceService from "@/services/device.js";
import EquipmentDataService from "@/services/equipment-data.js";
import MQTTTopicSpan from "@/components/registration/mqtt-topic-span.vue";
import MemoryTypeSelector from "@/components/registration/memory-type-selector.vue";
import InputCurrentValueLocalData from "@/components/registration/input-initial-value-local-data.vue";
import ValueSourceSelector from "@/components/editor/value-source-selector.vue";
import DataHistoryRateSelector from "@/components/data-history-rate-selector.vue";
import StandardCurveUploadForm from "@/components/registration/standard-curve-upload-form.vue";

import {initialValue as DftDataValueIndex} from "@/components/editor/value-source-selector.vue";
import {deviceListAdapter} from "@/services/device.js";
import {mqttTopic} from "@/services/equipment.js";
import {validNumber} from "@/plugins/utils.js";
import {warnUnsavedChanged} from "@/project/mixin-alert.js";
import {
  contractPlanConst,
  sourceValueConst,
  dataHistoryTypeConst
} from "@/assets/constants.js";
// Localization
import messages from "@/i18n/data";

function defaultData() {
  return {
    data: {
      id: "",
      name: "",
      description: "",
      identity_embedded_app: "",
      process_area: {id: "0"},
      inherits_parent_process_area: true,
      memory_type: {id: 0},
      memory_size: 1,
      string_length: 1,
      source_value: {id: ""},
      enabled: true,
      history_enabled: false,
      read_only: true,
      allowed_mapping_value: false,
      read_write_mode: 0,
      value_format_type: {id: ""},
      unity_label: "",
      custom_format: "",
      memory_address: "",
      local_storage_identity_number: "",
      calculation_type: {id: ""},
      calculation_param_a: null,
      calculation_param_b: null,
      calculation_param_c: null,
      calculation_param_d: null,
      number_active_alarms: 0,
      has_active_alarms: false,
      portal_data: null,
      user_data: null,
      current_value: null,
      update_value_mode_after_writing: {
        id: ""
      },
      etag: "",
      minimum_value: "",
      maximum_value: "",
      label: "",
      mqtt_qos: 0,
      mqtt_retain: false,
      data_history_type_id: "",
      is_local: false,
      current_value: "",
      history_tolerance_value: 0.0
    },
    process_area: {
      inherits_parent_process_area: false,
      id: 0
    },
    parent: {id: 0, name: ""},
    text_list_id: 0,
    showParentSelection: true,
    connectorModel: {
      propagate: false
    },
    durations: {
      milliseconds: "millisecond",
      seconds: "second",
      minutes: "minute",
      hours: "hour"
    },
    history_enabled: false, //original item,
    vector_enabled: false,
    allow_index_configuration: false, // temporary disabled,
    source: null,
    initialized: false
  };
}

export default {
  name: "FormData",
  extends: FormBase,
  i18n: {messages},
  components: {
    ProcessAreaSelection,
    FormParentsSelection,
    FormTextListInline,
    ToolTip,
    ClearHistory,
    FormCommonFieldsInline,
    FormInlineModel,
    ColorPicker,
    DataFormatInput,
    MQTTTopicSpan,
    MemoryTypeSelector,
    InputCurrentValueLocalData,
    ValueSourceSelector,
    DataHistoryRateSelector,
    StandardCurveUploadForm
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: true,
      default: 0
    },
    data_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return defaultData();
  },
  computed: {
    isMQTT() {
      return this._isMQTT(this?.connector);
    },
    topicPrefix() {
      return mqttTopic(this.data, -1);
    },
    fullTopic() {
      return mqttTopic(this.data);
    },
    label: {
      set(value) {
        this.$set(this.data, "label", this.$utils.asLabel(value));
      },
      get() {
        return this?.data?.label || "";
      }
    },
    mqtt_qos: {
      set(value) {
        this.$set(this.data, "mqtt_qos", parseInt(value));
      },
      get() {
        return this?.data?.mqtt_qos || 0;
      }
    },
    mqtt_retain: {
      set(value) {
        this.$set(this.data, "mqtt_retain", value);
      },
      get() {
        return this?.data?.mqtt_retain || false;
      }
    },
    getSourceValueConst() {
      return sourceValueConst || null;
    },
    hasIntervalError() {
      let min = parseFloat(this.data.minimum_value);
      let max = parseFloat(this.data.maximum_value);
      return min && max && max < min;
    },
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    dataId() {
      return parseInt(this.data_id || this.$route.params.data_id || 0);
    },
    connector() {
      return this?.data?.device?.connector || null;
    },
    device() {
      return this?.data?.device || null;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isFreePlan() {
      return (
        false || this?.contract?.contract_plan?.id == contractPlanConst.FREE
      );
    },
    filters() {
      let device = (this.data && this.data.device) || null;
      let connector = (device && device.connector) || null;
      let ret = [
        {
          entry: {
            id: parseInt(connector && connector.id) || this.connectorId || 0
          },
          filter_by: "connector_id",
          label: "connector",
          service: this.newConnectorService(!self.data_id),
          disabled: this.connector_id ? true : false
        },
        {
          entry: {
            id: parseInt(device && device.id) || this.deviceId || 0
          },
          filter_by: "device_id",
          filter_function: (device) => {
            if (this.data_id) return true;
            return device && !device.reference_device_id;
          },
          label: "device",
          service: new DeviceService(),
          disabled: this.device_id ? true : false
        }
      ];
      return ret;
    },
    isValid() {
      return this.data &&
        this.data.name &&
        this.process_area &&
        this.process_area.id &&
        this.data.memory_type &&
        this.data.memory_type.id &&
        this.data.source_value &&
        this.data.source_value.id &&
        this.format &&
        (this.format.format_mask == "text_list"
          ? this.text_list_id != 0
          : true) &&
        this.isStringLengthValid &&
        this.isMemoryVectorSizeValid &&
        this.historyToleranceValueIsValid &&
        (!this.isLocal || (this.isLocal && this.isValidCurrentValue)) &&
        (this.isLocal ||
          this.isMQTT ||
          (this.data.source_value.id == sourceValueConst.ADDRESS &&
            (this.data.memory_address ?? "") !== "") ||
          (this.data.source_value.id == sourceValueConst.LOCAL &&
            (this.data.local_storage_identity_number ?? "") !== "" &&
            (this.isReadOnly || (this.data.memory_address ?? "") !== "")) ||
          (this.cloneEnabled &&
            (this.device.is_reference || this.device.data_collector_device_id)))
        ? !this.errors
        : false;
    },
    parentProcessAreaId() {
      return (
        (this.data &&
          this.data.device &&
          this.data.device.process_area &&
          this.data.device.process_area.id) ||
        0
      );
    },
    references() {
      return (
        ("config" in this.$root &&
          "references" in this.$root.config &&
          this.$root.config.references) ||
        {}
      );
    },
    storageVersions() {
      return this.references.local_storage_versions || [];
    },
    isWritingAllowed() {
      return this.memoryType && "writing_allowed" in this.memoryType
        ? this.memoryType.writing_allowed
        : true;
    },
    isReadOnly() {
      return this.data.read_write_mode == 0; // || this.data.read_only;
    },
    memoryType() {
      return (
        (this?.data?.memory_type?.id &&
          this.memoryTypeList.find(
            ({id}) => id == this?.data?.memory_type?.id
          )) ||
        null
      );
    },
    memoryTypeList() {
      let self = this;
      let protocol_id = self?.data?.device?.connector?.protocol?.id;
      let lst = this.references.data_memory_types || [];
      let ret = lst.filter(function(i) {
        let ls = i.allowed_protocols.filter(function(p) {
          return p.id == protocol_id;
        });
        return (
          (self.isLocal && i.allows_use_in_local_data) ||
          (!self.isLocal && ls.length > 0)
        );
      });
      // simulation:
      // let test=false;ret=ret.map(function(i){i.writing_allowed=test;test=!test;return i;});
      return ret;
    },
    historyTypeList() {
      return [...(this.references.data_history_types || [])].reverse();
    },
    hintsHistoryType() {
      return [
        '<div class="text-left">',
        this.$t("hints.history_type"),
        ...this.historyTypeList.map(
          ({name, description}) =>
            `</br></br>- <b>${name}</b>:<br> ${description}`
        ),
        "</div>"
      ].join("");
    },
    historyTypeName() {
      return (
        (this.historyTypeList || []).find(
          ({id}) =>
            parseInt(id) == parseInt(this?.data?.data_history_type_id ?? -1)
        )?.name ?? ""
      );
    },
    quota_warning() {
      return (
        (this.data.history_enabled &&
          {2: true, 3: true, 4: true}[
            this?.data?.data_history_type_id || null
          ]) ||
        null
      );
    },
    sourceValueList() {
      let self = this;
      let lst = self.references.data_value_source_types || [];
      if (
        self.isFreePlan ||
        (self.data &&
          self.data.device &&
          !self.data.device.local_storage_enabled)
      ) {
        lst = lst.filter((i) => i.number != 2);
      }
      return lst;
    },
    formatList() {
      let lst = this?.references?.data_value_format_types || [];
      if (this.isString) {
        lst = lst.filter(({format_mask}) => format_mask == "%s");
      } else if (!this?.contract?.allowed_text_list) {
        lst = lst.filter(
          ({format_mask}) => format_mask != "text_list" && format_mask != "%s"
        );
      } else {
        lst = lst.filter(({format_mask}) => format_mask != "%s");
      }
      return lst;
    },
    format() {
      return (
        this.formatList.find(
          ({id}) => this?.data?.value_format_type?.id == id
        ) || null
      );
    },
    calculationList() {
      return [
        {
          id: "0",
          name: this.$tc("none")
        }
      ].concat(this.references.data_calculation_types || []);
    },
    payload() {
      let self = this;
      let payload = {...defaultData().data, ...self.data};
      if (self.data.id) {
        payload.id = self.data.id;
      }
      payload.device_id = this.parent.id;
      payload.process_area_id = this.process_area.id;
      payload.inherits_parent_process_area = this.process_area.inherits_parent_process_area;
      payload.memory_type_id = payload.memory_type.id;
      payload.source_value_id = payload.source_value.id;

      payload.value_format_type_id = payload.value_format_type.id;
      payload.update_value_mode_after_writing_id =
        payload.update_value_mode_after_writing.id;

      if (self.connectorModel.propagate) {
        payload.apply_changes_to_instances = true;
      }

      if (parseInt(payload.calculation_type.id)) {
        payload.calculation_type_id = payload.calculation_type.id;
      } else {
        payload.calculation_type_id = null;
        payload.calculation_param_a = null;
        payload.calculation_param_b = null;
        payload.calculation_param_c = null;
        payload.calculation_param_d = null;
      }
      if (this?.format?.format_mask == "text_list") {
        payload.text_list_id = this.text_list_id;
      } else if (this?.format?.format_mask == "%s") {
        if (payload.custom_format.indexOf("%s") == -1) {
          payload.custom_format = this.format.format_mask;
        }
      }
      if (payload.minimum_value === "") {
        payload.minimum_value = null;
      }
      if (payload.maximum_value === "") {
        payload.maximum_value = null;
      }
      if (payload.memory_size > 1) {
        payload.history_enabled = false;
        payload.allowed_mapping_value = false;
      }

      delete payload.device;
      delete payload.memory_type;
      delete payload.source_value;
      delete payload.value_format_type;
      delete payload.calculation_type;
      delete payload.process_area;
      delete payload.update_value_mode_after_writing;
      delete payload.read_only; // still exists for reading but not editable (replaced by read_write_mode)
      delete payload.deleted_at;

      if (!payload.history_enabled) {
        delete payload.data_history_type_id;
      }

      if (this.isMQTT) {
        // TODO: mqtt has no address and source
        delete payload.source_value_id;
        delete payload.memory_address;
        delete payload.local_storage_identity_number;

        if (
          this._isVirtualDevice(this.device) &&
          this.data.label &&
          !payload.is_local
        ) {
          // payload.label = `${this.device.reference_id}/${payload.label}`;
          payload.label = payload.label.replace(/.*\//, "");
        }
      } else {
        if (payload.is_local) {
          payload.label = "";
        }
        if (
          payload.source_value_id == sourceValueConst.ADDRESS &&
          payload.local_storage_identity_number !== ""
        ) {
          payload.local_storage_identity_number = null;
        }
        if (payload.memory_address === "") {
          payload.memory_address = null;
        }
        if (payload.local_storage_identity_number === "") {
          payload.local_storage_identity_number = null;
        }
      }

      if (!payload.is_local || !payload.current_value) {
        payload.current_value = null;
      }

      return payload;
    },
    removalMessage() {
      let msg = "";
      let item = this.data;
      if (item.device.connector.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("data");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      } else {
        msg = this.warningContent(
          "data",
          item.name,
          "you_wont_be_able_to_revert_this"
        );
      }
      return msg;
    },
    errors() {
      let entry = null;
      if (!this.data.name) return {name: "invalid_name"};
      (this.$store.getters["dashboard/dataList"] || [])
        .filter(
          ({device}) =>
            // Mesmo Dispositivo
            parseInt(device?.id) == parseInt(this?.data?.device?.id) ||
            // Mesmo Dispositivo Coletor
            (device?.data_collector_device_id &&
              this?.data?.device?.data_collector_device_id &&
              parseInt(device?.data_collector_device_id) ==
                parseInt(this?.data?.device?.data_collector_device_id)) ||
            // Dispositivo Coletor (atual) é usado pelo Dispositivo Virtual
            (device?.data_collector_device_id &&
              parseInt(device?.data_collector_device_id) ==
                parseInt(this?.data?.device?.id)) ||
            // Dispositivo Virtual (atual) usa o Dispositivo Coletor
            (this?.data?.device?.data_collector_device_id &&
              parseInt(device?.id) ==
                parseInt(this?.data?.device?.data_collector_device_id))
        )
        .forEach((item) => {
          // Valida Endereço do Dado Repetido
          if (
            this.data.memory_address !== "" &&
            parseInt(this?.data.memory_address) ==
              parseInt(item?.memory_address) &&
            parseInt(this?.memoryType?.id) == item.memory_type.id &&
            parseInt(this.data.source_value?.id) ==
              this.getSourceValueConst.ADDRESS &&
            parseInt(this?.data?.id || 0) != parseInt(item?.id) &&
            // Sem Tipo de Cálculo
            parseInt(this?.data?.calculation_type?.id || 0) === 0 &&
            // Vetor / Não Vetor
            ((parseInt(this.data?.memory_size) == 1 &&
              parseInt(item.memory_size) == 1) ||
              (parseInt(this.data?.memory_size) > 1 &&
                parseInt(item.memory_size) > 1)) &&
            // Origem do Valor
            parseInt(this.data.source_value?.id) == parseInt(item.source_value)
          ) {
            entry = entry || {};
            entry.memory_address = "invalid_memory_address";
          }

          // Valida Identificador na Base Local repetido
          if (
            this.data.local_storage_identity_number !== "" &&
            parseInt(this?.data.local_storage_identity_number) ==
              parseInt(item?.local_storage_identity_number) &&
            parseInt(this.data.source_value?.id) ==
              this.getSourceValueConst.LOCAL &&
            parseInt(this?.data?.id || 0) != parseInt(item?.id) &&
            // Origem do Valor
            parseInt(this.data.source_value?.id) == parseInt(item.source_value)
          ) {
            entry = entry || {};
            entry.local_storage_identity_number =
              "invalid_local_storage_identity_number";
          }

          // Valida Nome repetido
          if (
            this.canNameIt &&
            this.data.name !== "" &&
            this.$utils.compareStr(this.data.name, item.name) &&
            parseInt(this?.data?.device?.id) == parseInt(item?.device?.id) &&
            parseInt(this?.data?.id || 0) != parseInt(item?.id)
          ) {
            entry = entry || {};
            entry.name = "invalid_name";
          }
        });

      // Nome de Dado com apenas números não é aceito
      if (this.data.name == parseInt(this.data.name) + "") {
        entry = entry || {};
        entry.name = "invalid_name";
      }

      return entry;
    },
    basicType() {
      return this?.memoryType?.basic_type || null;
    },
    isString() {
      return (this?.basicType?.label || "").toLowerCase() == "string";
    },
    isBool() {
      return (this?.basicType?.label || "").toLowerCase() == "bool";
    },
    isFloat() {
      return (this?.basicType?.label || "").toLowerCase() == "float";
    },
    isInteger() {
      return (this?.basicType?.label || "").toLowerCase() == "integer";
    },
    valueFormatTypeId: {
      set(value) {
        this.data.value_format_type.id = value;
      },
      get() {
        return this?.data?.value_format_type?.id || "";
      }
    },
    memorySize: {
      // although, name be memory_size, it is the number of elements
      // a vector might alocate
      set(value) {
        let vlr = parseInt(value);
        if (!vlr) return;
        if (vlr < 1) {
          vlr = 1;
        } else if (vlr > this.memoryType.vector_max_size) {
          vlr = this.memoryType.vector_max_size;
        }
        this.vector_enabled = vlr != 1;
        return (this.data.memory_size = vlr);
      },
      get() {
        return this.data.memory_size || 1;
      }
    },
    stringLength: {
      set(value) {
        // TODO: min/max must be revisited, once backend attributes have been changed
        let vlr = parseInt(value);
        if (!vlr) return;
        if (vlr < 1) {
          vlr = 1;
        } else if (vlr > this.memoryType.max_length) {
          vlr = this.memoryType.max_length;
        }
        return (this.data.string_length = vlr);
      },
      get() {
        return this.data.string_length || 1;
      }
    },
    memoryVectorSizeConstraints() {
      return !this.isMQTT && (this?.memoryType?.vector_max_size || 0) > 1
        ? {
            min: this.vector_enabled ? 2 : 1,
            // size in bytes==2 - string buffer (allow even and odd steps)
            inc: 1,
            max: this.memoryType.vector_max_size
          }
        : null;
    },
    isMemoryVectorSizeValid() {
      return (
        !this.memoryVectorSizeConstraints ||
        (this.memorySize >= this.memoryVectorSizeConstraints.min &&
          this.memorySize <= this.memoryVectorSizeConstraints.max &&
          (this.memoryVectorSizeConstraints.inc == 1 ||
            parseInt(this.memorySize % this.memoryVectorSizeConstraints.inc)))
      );
    },
    stringLengthConstraints() {
      return !this.isLocal &&
        !this.isMQTT &&
        (this?.memoryTypeMaxLength || 0) > 1
        ? {
            min: 1,
            // size in bytes==2 - string buffer (allow even and odd steps)
            inc: this.memoryType.size_in_bytes == 2 ? 1 : 2,
            max: this.memoryTypeMaxLength
          }
        : null;
    },
    isStringLengthValid() {
      return (
        !this.stringLengthConstraints ||
        (this.stringLength >= this.stringLengthConstraints.min &&
          this.stringLength <= this.stringLengthConstraints.max &&
          (this.stringLengthConstraints.inc == 1 ||
            parseInt(this.stringLength % this.stringLengthConstraints.inc)))
      );
    },
    memoryTypeMaxLength() {
      return this.isMQTT ? null : this?.memoryType?.max_length || 0;
    },
    customFormat: {
      set(value) {
        this.data.custom_format = value;
      },
      get() {
        return this?.data?.custom_format || "";
      }
    },
    canRemove() {
      return this.data_id &&
        !this.isModelBased &&
        this.device &&
        (!this.device?.data_collector_device_id ||
          !this.device?.reference_device_id)
        ? true
        : false;
    },
    canNameIt() {
      return !this.isModelBased && !this.device?.reference_device_id;
    },
    areMemoryFieldsDisabled() {
      return (
        !this.device ||
        this.busy ||
        this.isModelBased ||
        (this.data_id && this.isLocal) ||
        (this.device.reference_device_id &&
          parseInt(this.device.reference_device_id) != parseInt(this.device.id))
      );
    },
    color: {
      set(value) {
        let portal_data = this.data.portal_data || {};
        portal_data.color = value;
        this.$set(this.data, "portal_data", portal_data);
      },
      get() {
        return this?.data?.portal_data?.color || "#FFFFFF";
      }
    },
    waveform: {
      set(value) {
        let portal_data = this.data.portal_data || {};
        portal_data.wave_form = value;
        this.$set(this.data, "portal_data", portal_data);
      },
      get() {
        return this?.data?.portal_data?.wave_form || "triangle";
      }
    },
    isLocal: {
      set(value) {
        this.$set(this.data, "is_local", value);
        if (value) {
          this.$set(this.data, "read_write_mode", 1);
          this.$set(this.data, "history_enabled", false);
          this.$set(this.data, "label", "");
        }
      },
      get() {
        return this?.data?.is_local ?? false;
      }
    },
    accessModeBlockVisible() {
      return !this.isLocal;
    },
    configurationsBlockVisible() {
      return (
        (this.memorySize == 1 || !this.memoryVectorSizeConstraints) &&
        !this.isLocal &&
        !this.isMQTT
      );
    },
    historyTypeBlockVisible() {
      return (
        !this.isLocal &&
        (this.memorySize == 1 || !this.memoryVectorSizeConstraints)
      );
    },
    mqttQosBlockVisible() {
      return this.isMQTT && !this.isReadOnly && !this.isLocal;
    },
    mqttRetainBlockVisible() {
      return this.isMQTT && !this.isReadOnly && !this.isLocal;
    },
    genericMemoryTypeSelectorColClass() {
      if (this.isLocal) {
        return "col-md-4";
      }

      if (this.memoryVectorSizeConstraints || this.stringLengthConstraints) {
        return "col-md-4";
      }

      return "col-md-6";
    },
    currentValueEnabled() {
      return this.isLocal;
    },
    isValidCurrentValue() {
      if (!this?.data?.current_value) {
        return true;
      }
      if (this?.memoryType?.name == "Número") {
        let value = this?.data?.current_value || "";
        value = value.replace(",", ".");
        return validNumber(value);
      } else if (this?.memoryType?.name == "String") {
        return this?.data?.current_value?.length <= 246;
      }
      return true;
    },
    accessModeList() {
      const lst = [
        {id: "0", title: "access_mode.read_only"},
        {id: "2", title: "access_mode.write_only"},
        {id: "1", title: "access_mode.read_write"}
      ];
      return this.isLocal ? [lst[2]] : lst;
    },
    alarmEnabled() {
      return this.memorySize > 1 || this.data.is_local ? false : true;
    },
    dataValueIndex: {
      set(val) {
        // TODO: Remove as soon as a dedicated data property is available
        if (!this.allow_index_configuration) return;
        if (!(this.memorySize > 1) || !val) return;
        let entry = {...val};
        if (entry.type == "constant") {
          entry.value =
            entry.value !== "" && !isNaN(Number(entry.value))
              ? parseInt(entry.value)
              : -1;
          if (entry.value < 0) entry.value = -1;
          else if (entry.value >= this.memorySize)
            entry.value = this.memorySize - 1;
        }
        let portal_data = this.data.portal_data || {};
        portal_data.data_value_index = entry;
        this.$set(this.data, "portal_data", portal_data);
      },
      get() {
        // TODO: Remove as soon as a dedicated data property is available
        if (!this.allow_index_configuration) return null;
        if (!(this.memorySize > 1)) return null;
        let portal_data = this.data.portal_data || {};
        let entry = portal_data.data_value_index || DftDataValueIndex();
        if (entry.type == "constant" && !(parseInt(entry.value) >= 0)) {
          // entry.value = 0;
        }
        return entry;
      }
    },
    vectorEnabled: {
      set(val) {
        this.vector_enabled = val;
        if (val && this.data.memory_size <= 1) {
          this.data.memory_size = 2;
        } else if (!val && this.data.memory_size > 1) {
          this.data.memory_size = 1;
        }
        this.$refs.memorySize.focus();
      },
      get() {
        return this.vector_enabled;
      }
    },
    computedHistoryToleranceValue: {
      get() {
        if (this.data.history_tolerance_value == 0) return "";
        return this.data.history_tolerance_value;
      },
      set(value) {
        if (value == "" || value == 0) {
          this.$set(this.data, "history_tolerance_value", 0);
        } else {
          this.$set(this.data, "history_tolerance_value", value);
        }
      }
    },
    historyToleranceValueMax() {
      return this.isBool ? 1 : 999999999;
    },
    dataHistoryRateVisible() {
      return (
        this.data.history_enabled &&
        (this.data.data_history_type_id == dataHistoryTypeConst.PERIODIC ||
          this.data.data_history_type_id ==
            dataHistoryTypeConst.PERIODIC_AND_CHANGES)
      );
    },
    historyToleranceValueVisible() {
      return (
        this.data.history_enabled &&
        !this.isString &&
        (this.data.data_history_type_id == dataHistoryTypeConst.CHANGES ||
          this.data.data_history_type_id ==
            dataHistoryTypeConst.PERIODIC_AND_CHANGES)
      );
    },
    historyToleranceValueIsValid() {
      if (!this.historyToleranceValueVisible) return true;
      let history_tolerance_value = this?.data?.history_tolerance_value;
      if (history_tolerance_value == null || history_tolerance_value === "")
        return false;
      let hasComma = (history_tolerance_value + "").indexOf(".") >= 0;
      if (this.isBool) {
        let value = parseInt(history_tolerance_value);
        return (value == 0 || value == 1) && !hasComma;
      } else if (this.isFloat || this.isInteger) {
        return (
          validNumber(history_tolerance_value) &&
          ((this.isInteger && !hasComma) || this.isFloat)
        );
      }
      return true;
    },
    standardCurve: {
      set(value) {
        this.data.portal_data = this.data.portal_data || {};
        if (value) {
          this.$set(this.data.portal_data, "standard_curve", value);
        } else {
          this.$delete(this.data.portal_data, "standard_curve");
        }
      },
      get() {
        return (this?.data?.portal_data ?? {})?.standard_curve ?? null;
      }
    },
    styleInputAddon() {
      if (this.$root.isDarkTheme) {
        if (this.isModelBased) {
          return {
            'background-color': 'var(--skin-dark-dark)'
          }
        } else {
          return {
            'background-color': 'var(--skin-dark-medium)'
          }
        }
      } else {
        if (this.isModelBased) {
          return {
            'background-color': '#eee'
          }
        } else {
          return {
            'background-color': 'inherit'
          }
        }
      }
    }
  },
  watch: {
    "data.name": {
      handler(n, o) {
        if (this.isMQTT && !this.isLocal) {
          if (!this.label || this.label == this.$utils.asLabel(o)) {
            this.label = n;
          }
        } else if (this.isLocal) {
          this.label = "";
        }
        this.$emit(
          "titleChanged",
          `${(this.dataId &&
            (this.cloneEnabled ? this.originalName : this.data.name)) ||
            this.$tc("new", 1)}${
            this.cloneEnabled ? " (" + this.$t("copying") + ")" : ""
          }`
        );
      },
      deep: true,
      immediate: true
    },
    "data.history_enabled": {
      handler(n, o) {
        if (n && !o && !this.data?.data_history_type_id)
          this.data.data_history_type_id = dataHistoryTypeConst.PERIODIC;
      }
    },
    text_list_id(n, o) {
      if (!n && o) {
        // text_list_id deleted - this data etag is invalid
        this.data.etag = "";
      }
    },
    busy(n) {
      this.$emit("loading", n);
    },
    isWritingAllowed(n) {
      if (!n) {
        this.data.read_only = true;
        this.data.read_write_mode = 0;
      }
    },
    parent(n) {
      if (n && n.id && n.id != (this?.data?.device?.id || "")) {
        let device = this.$store.getters["dashboard/deviceList"].find(
          ({id}) => parseInt(id) == parseInt(n.id)
        );
        if (device) {
          // this.busy = true;
          this.ready = false;
          let data = structuredClone(this.data);
          data.device = device;
          this.initData(data);
          // this.$nextTick(function() {
          //   // this.busy = false;
          //   this.ready = true;
          // });
        }
      }
    },
    data_id(n, o) {
      if (!n && o) {
        this.ready = false;
        this.showParentSelection = false;
        this.$nextTick(() => {
          this.resetData();
          this.setup();
          this.showParentSelection = true;
        });
      }
    },
    format(n, o) {
      if (!n || !this.basicType) return;
      if (this.isString) {
        if (this.customFormat.indexOf("%s") == -1) {
          this.customFormat = n.format_mask;
        }
      } else if (!this.isBool) {
        if (o && n.id != o.id) {
          if (n.format_mask == "duration") {
            if (
              !this.data.unity_label ||
              Object.keys(this.durations).indexOf(this.data.unity_label) == -1
            ) {
              this.data.unity_label = "seconds";
            }
          } else if (o.format_mask == "duration" && this.data.unity_label) {
            this.data.unity_label = "";
          }
          this.customFormat = "";
        }
      }
    },
    memoryTypeList: {
      handler(n) {
        if (n && n.length) {
          let memoryType = n.find(({id}) => this.data?.memory_type?.id == id);
          this.$set(this.data, "memory_type", {
            id: memoryType ? memoryType.id : this.data.id ? null : n[0].id
          });
        }
      },
      immediate: true,
      deep: true
    },
    formatList(n) {
      if (!n || !n.length) return;
      if (!n.find(({id}) => parseInt(id) == parseInt(this.valueFormatTypeId))) {
        this.valueFormatTypeId = n[0].id;
      }
    },
    basicType: {
      handler(n, o) {
        if (n && o && n.type != o.type) {
          this.customFormat = "";
          this.stringLength =
            n.type === "string" ? this.memoryType.max_length : 1;
          this.memorySize = 1;
        }
      },
      immediate: true
    },
    memoryTypeMaxLength: {
      handler(n, o) {
        if (n && o) {
          this.memorySize = n;
        }
      },
      immediate: true
    },
    payload(n) {
      if (n && typeof this.$parent.updateHash == "function") {
        this.$parent.updateHash(n);
      }
    },
    alarmEnabled: {
      handler(n, o) {
        this.$emit("alarmEnabled", n ? true : false);
        if (o !== undefined && o !== null) {
          this.nav(this.data.name);
        }
      },
      immediate: true
    }
  },
  methods: {
    resetData() {
      let data = defaultData();
      Object.keys(data).forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(this.$data, k)) {
          this.$data[k] = data[k];
        }
      });
    },
    setLabel($event) {
      if (this.isModelBased) return;
      this.label = $event?.target?.value || "";
      $event.target.value = this.label;
    },
    async fetchDevice(id) {
      return new Promise((resolve) => {
        let srv = new DeviceService();
        srv.get(id).then((ret) => {
          resolve(ret);
        });
      });
    },
    async fetchConnector(id) {
      return new Promise((resolve) => {
        let srv = new ConnectorService();
        srv.get(id).then((ret) => {
          resolve(ret);
        });
      });
    },
    async fetchData() {
      return new Promise((resolve) => {
        let data = (this.$store.getters["dashboard/dataList"] || []).find(
          ({id}) => parseInt(id) == parseInt(this.data_id)
        );
        if (data) {
          resolve(JSON.parse(JSON.stringify(data)));
          return;
        } else {
          this.service
            .get(this.data_id, this.contract && this.contract.id)
            .then((ret) => {
              resolve(ret);
            });
        }
      });
    },
    equipmentDataRequest(forceUpdate) {
      var query = {
        resource: "data",
        connectorId: this.connectorId,
        forceUpdate: forceUpdate
      };
      return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    calculationParamsFrom(calcTypeId) {
      let calculationType = this.calculationList.find(
        (calcType) => calcType.id == calcTypeId
      );
      let calculationParams = [];
      if (
        calculationType &&
        calculationType.portal_data &&
        calculationType.portal_data.calculation_params_configurations
      ) {
        for (let [key, value] of Object.entries(
          calculationType.portal_data.calculation_params_configurations || []
        )) {
          if (!key.includes("calculation_param")) continue;

          if (value.required) {
            value.letter = key.split("_").pop();
            calculationParams.push(value);
          }
        }
      }
      this.updatePopovers();
      return calculationParams;
    },
    async updatePopovers() {
      await this.$nextTick();
      $(this.$el)
        .find("[data-toggle=popover]")
        .popover({
          placement: "auto",
          trigger: "hover",
          delay: {show: 300}
        });
    },
    _save(stay, addAnother) {
      let self = this;
      let payload = self.payload;
      self.busy = true;
      self.service
        .save(payload)
        .then((ret) => {
          self.busy = false;
          if (!payload.id && ret && ret.id) {
            ret = [ret];
          }
          if (ret && ret.length && ret[0].id) {
            if (self.validateSaveResponse(ret[0])) {
              self.onSaveSuccess(this.service.dataAdapter(ret), "data");
              if (this?.data?.device?.is_reference) {
                this._forceReloadOnDestroy = true;
              }
              self.showAlert(() => {
                if (addAnother) {
                  if (
                    this.$route.path !=
                    `/dashboard/edit/connector/${this.connectorId}/device/${this.device_id}/data/0`
                  )
                    this.$router.push(
                      `/dashboard/edit/connector/${this.connectorId}/device/${this.device_id}/data/0`
                    );
                  this.$emit("updateKey");
                } else if (stay) {
                  if (self.$route.path.endsWith("/0")) {
                    self.$router.push({
                      name: "route-data-form",
                      params: {
                        data_id: self.data.id,
                        device_id: self.data.device.id,
                        connector_id: self.data.device.connector.id
                      }
                    });
                    self.nav(self.data.name);
                  }
                } else {
                  self.close("save");
                }
              }, true);
              return;
            }
          }
          self.validateSaveResponse(ret);
          self.showAlert();
        })
        .catch((error) => {
          self.busy = false;
          self.validateSaveResponse(error);
          self.showAlert();
        });
    },
    validateCriticalChanges() {
      return new Promise((resolve) => {
        // Sometimes the memory type is not selected, but it should.
        if (!this.memoryType) {
          this.$swal({
            title: this.$t("warning"),
            content: this.$t("memory_type_not_selected"),
            icon: "warning"
          }).then(() => {
            resolve(false);
          });
          return;
        }

        let lst = [];
        if (this.data.id) {
          if (this.source.memory_size != this.data.memory_size) {
            lst.push({
              f: "titles.vector_size",
              t: "hints.sample_loss_warning"
            });
          }
        }
        // add any other validation here... e.g. lst.push("data");
        // let txt = this.$t("critical_changes_detected", { item: '<div style="font-size: 8pt;color:#666;"><b>Tamanho do Vetor: As alterações poderão acarretar em perda de valores amostrados</b></div>' })
        let txt = this.$t("critical_changes_detected", {
          item: lst
            .map(
              ({f, t}) =>
                `<div style="margin: 5px -15px 0 -15px;font-size: 9pt;color:#666;"><b>${this.$tc(
                  f,
                  1
                )}: ${this.$tc(t, 1)}</b></div>`
            )
            .join("")
        });
        if (lst.length) {
          warnUnsavedChanged(
            this,
            `<div class="text-danger"><i class="fa fa-exclamation-triangle"></i> ${txt}</div>`,
            (r) => resolve(r)
          );
        } else {
          resolve(true);
        }
      });
    },
    save(stay, addAnother) {
      let self = this;
      let isNew = !self.data.id;
      let isModel = self.isModel;
      this.validateResourceQuota(
        "data_plural",
        self.data.history_enabled
          ? this.contract.maximum_histories
          : this.contract.maximum_data,
        (self.data.history_enabled
          ? this.contract.registered_histories
          : this.contract.registered_data) - (!isNew || isModel ? 1 : 0)
      ).then((resp) => {
        if (resp == "proceed") {
          this.validateCriticalChanges().then((confirm) => {
            if (!confirm) return;
            if (!isNew && !self.data.etag) {
              self.busy = true;
              self.service.get(self.data.id).then((data) => {
                if (data && data.etag) {
                  self.data.etag = data.etag;
                  self._save(stay, addAnother);
                } else {
                  self.busy = false;
                }
              });
            } else {
              this._save(stay, addAnother);
            }
          });
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    clone() {
      let self = this;
      let payload = self.payload;
      delete payload.id;
      self.busy = true;
      self.service.duplicate(self.data_id, payload).then((ret) => {
        self.busy = false;
        if (self.validateSaveResponse(ret)) {
          self.onSaveSuccess(this.service.dataAdapter(ret), "data");
          self.showAlert(() => {
            self.close("clone");
          });
        } else {
          self.showAlert();
        }
      });
    },
    remove() {
      this.doRemove(this.payload, this.service, (removed) => {
        if (!removed) return;
        this.$store.dispatch("dashboard/removeResources", [
          {data_id: this.payload.id}
        ]);
      });
    },
    initData(inMemoryData) {
      if (!inMemoryData) {
        this.nav("invalid_data");
        return;
      }
      let data = JSON.parse(JSON.stringify(inMemoryData));
      if (data.device && data.device.id) {
        deviceListAdapter([data.device]);
        this.parent = {id: data.device.id, name: data.device.name};
        this.text_list_id = (data.text_list && data.text_list.id) || 0;
        data.process_area = data.process_area || {id: 0};
        data.source_value =
          data.source_value && data.source_value.id
            ? data.source_value
            : {
                id:
                  this.sourceValueList && this.sourceValueList.length
                    ? this.sourceValueList[0].id
                    : 0
              };

        // current_value
        data.current_value = `${data?.current_value?.value ?? ""}`;

        // format
        data.value_format_type =
          data.value_format_type && data.value_format_type.id
            ? data.value_format_type
            : {
                id:
                  this.formatList && this.formatList.length
                    ? this.formatList[0].id
                    : 0
              };

        // calculation
        data.calculation_type =
          data.calculation_type && data.calculation_type.id
            ? data.calculation_type
            : {
                id:
                  this.calculationList && this.calculationList.length
                    ? this.calculationList[0].id
                    : 0
              };

        // process area setup
        var process_area = {
          inherits_parent_process_area: data.inherits_parent_process_area,
          id: 0
        };
        if (process_area.inherits_parent_process_area) {
          process_area.id =
            (data.device &&
              data.device.process_area &&
              data.device.process_area.id) ||
            data.process_area.id ||
            "";
        } else {
          process_area.id = (data.process_area && data.process_area.id) || "";
        }
        if (
          (
            this?.$root?.config?.references
              .data_update_value_modes_after_writing || []
          ).length &&
          !data.update_value_mode_after_writing.id
        ) {
          data.update_value_mode_after_writing.id = this.$root.config.references.data_update_value_modes_after_writing[0].id;
        }
        if (
          this._isMQTT(data?.device?.connector) &&
          this._isVirtualDevice(data?.device) &&
          !this.isLocal &&
          data.label
        ) {
          data.label = data.label.replace(`${data.device.reference_id}/`, "");
        }
        // clonning
        if (this.cloneEnabled) {
          data.name = "";
          data.description = "";
          data.identity_embedded_app = "";
          data.label = "";
          data.local_storage_identity_number = "";
          data.memory_address = "";
        } else {
          if (!data.id && this.device_id) {
            data.memory_address = "";
            data.local_storage_identity_number = "";
          }
        }
        this.vector_enabled = data.memory_size != 1;
        this.$set(this, "process_area", process_area);
        this.$set(this, "data", data);
        this.$set(this, "source", JSON.parse(JSON.stringify(data)));
        this.ready = true;
        return;
      }
    },
    nextAddr() {
      // return this.device_id
      //   ? (max(
      //       (this.$store.getters["dashboard/dataList"] || [])
      //         .filter(
      //           ({ device }) => parseInt(this.device_id) == parseInt(device.id)
      //         )
      //         .map(({ memory_address }) => parseInt(memory_address))
      //     ) ?? 0) + 1
      //   : 1;
      return "";
    },
    setup() {
      let self = this;
      if (self.data_id) {
        //read the device and the connector at once
        self.busy = true;
        self.fetchData().then((data) => {
          self.busy = false;
          if (!data) {
            self.showParentSelection = false;
            self.noData();
            return;
          }
          // original value
          this.originalName = data.name;
          this.history_enabled = data.history_enabled;
          self.cloneEnabled = self.$utils.gup("a") == "c";
          self.initData(data);
          if (self.cloneEnabled) {
            this.nav(`${self.data.name} (${self.$t("copying")})`);
          } else if (this.$utils.gup("a") == "d") {
            this.nav(`${self.data.name} (${self.$t("deleting")})`);
            self.$nextTick(function() {
              self.onButtonRemoveClick();
            });
          } else {
            this.nav(this.data.name);
          }
        });
        return;
      } else {
        if (self.device_id) {
          //read the device, creates an dummy data and associate them
          self.busy = true;
          self.fetchDevice(self.device_id).then((device) => {
            self.busy = false;
            if (device) {
              let data = defaultData().data;
              data.device = device;
              self.data = JSON.parse(JSON.stringify(data));
              self.initData(data);
              self.nav(self.$tc("new"));
            }
          });
        } else {
          if (self.connector_id) {
            //read the device, creates an dummy data and associate them
            self.busy = true;
            self.fetchConnector(self.connector_id).then((connector) => {
              self.busy = false;
              if (connector) {
                let data = defaultData().data;
                data.device = {
                  id: "0",
                  name: "",
                  connector: {
                    id: connector.id,
                    name: connector.name
                  }
                };
                self.initData(data);
                self.nav(self.$tc("new"));
              }
            });
          } else {
            let data = defaultData().data;
            data.device = {
              id: "0",
              name: "",
              connector: {
                id: "0",
                name: ""
              }
            };
            self.initData(data);
            self.nav(self.$tc("new"));
          }
        }
      }
    },
    nav(title) {
      let data = this.data || null;
      let device = (data && data.device) || null;
      let connector = (device && device.connector) || null;
      let items = [
        {name: "connector_plural", url: `/dashboard/edit/connector`},
        {
          name: (connector && connector.name) || this.connectorId,
          url: `/dashboard/edit/connector/${this.connectorId}`
        }
      ];
      items = items.concat([
        {
          name: "device_plural",
          url: `/dashboard/edit/connector/${this.connectorId}/device`
        },
        {
          name: (device && device.name) || this.deviceId,
          url: `/dashboard/edit/connector/${this.connectorId}/device/${this.deviceId}`
        }
      ]);
      items = items.concat([
        {
          name: "data_plural",
          url: `/dashboard/edit/connector/${this.connectorId}/device/${this.deviceId}/data`
        },
        {name: data.name, url: ""}
      ]);
      this.$emit("titleChanged", title);
      this.$emit("navChanged", {
        previous: "/dashboard/edit/connector",
        items: items
      });
    },
    _isMQTT(connector) {
      return connector?.protocol?.is_mqtt_protocol || false;
    },
    _isVirtualDevice(device) {
      return (
        device.data_collector_device_id &&
        parseInt(device.data_collector_device_id) != parseInt(device.id)
      );
    },
    toggleDataValueIndex() {
      let portal_data = this.data.portal_data || {};
      let entry = portal_data.data_value_index || DftDataValueIndex();
      entry.enabled = !entry.enabled;
      if (entry.enabled) {
        entry.type = "constant";
        entry.value = 0;
      } else {
        entry.type = "constant";
        entry.value = -1;
      }
      portal_data.data_value_index = entry;
      this.$set(this.data, "portal_data", portal_data);
    },
    memoryTypeListParser(lst) {
      return this.isLocal
        ? (this?.$root?.config?.references?.data_memory_types || []).filter(
            ({allows_use_in_local_data}) => allows_use_in_local_data == true
          )
        : lst.filter(({vector_max_size}) =>
            this.memorySize > 1 ? vector_max_size > 1 : true
          );
    }
  },
  created() {
    this.$emit("titleChanged", this.data_id ? this.data_id : this.$tc("new"));
    this.rule = "DadoEscrita";
    this.service = new EquipmentDataService();
    this.parentService = new DeviceService();
    this.equipmentDataRequest(true).then((r) => {
      this.setup();
      this.initialized = true;
    });
  },
  beforeDestroy() {
    if (!this._forceReloadOnDestroy) return;
    const _reload = (cid, dispatch) => {
      setTimeout(() => {
        dispatch("dashboard/fetchResourcesFrom", {
          connectorId: cid,
          resource: "data",
          forceUpdate: true
        });
      }, 2000);
    };
    _reload(this.connector_id, this.$store.dispatch);
  }
};
</script>

<style scoped>
.hideIt {
  display: none;
}

label {
  white-space: nowrap;
}
label.checkbox-inline {
  font-size: 12pt;
  margin: 5px 25px 0 0px;
}

label.checkbox-inline > input {
  width: 18px;
  height: 18px;
}

div.form-group-inline {
  width: 50%;
  display: inline-block;
}

.data-configuration {
  margin: 20px 0;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
  /* overflow: hidden; */
}

.skin-dark .data-configuration {
  background-color: var(--skin-dark-allblack);
}

.calculation_param:focus:invalid {
  border-color: #e42d2d;
}
.no-focus-border:focus {
  outline-width: 0;
  border-color: #d2d6de;
}
.input-prefix-label {
  padding-right: 0px;
  line-height: 1.42857143;
  font-weight: 600;
  color: #999;
}
.skin-dark .input-prefix-label {
  color: var(--skin-dark-light);
}

input:out-of-range {
  color: orangered;
}

input.invalid {
  color: orangered;
}

.sm-new-line {
  clear: left;
}

@media (min-width: 1200px) {
  .sm-new-line {
    clear: unset;
  }
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
.skin-dark .clicable:hover {
  opacity: 1;
  color: #fff;
}

.form-inline.custom-data-view > .form-group > label {
  display: block;
}

.form-inline.custom-data-view > .form-group {
  margin-right: 25px;
  /* min-width: 30%; */
}

.color-picker-wrapper::v-deep > .btn-sm {
  background: white;
  border: 1px solid gray;
  padding: 2px;
}

.skin-dark .small.text-info {
  color: var(--skin-dark-semilight);
}
</style>
